<template>
  <v-container fluid>
    <div class="div-margin">
      <v-row>
        <v-col cols="12" lg="2" xl="2" md="12" sm="12" class="fill-height mb-n3">
          <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
              class="btn-height"
          >
            <v-btn style="height: 56px">
              <v-icon>mdi-view-list-outline</v-icon>
            </v-btn>
            <v-btn style="height: 56px">
              <v-icon>mdi-card-bulleted-outline</v-icon>
            </v-btn>
            <v-btn style="height: 56px">
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
            <v-btn style="height: 56px">
              <v-icon>mdi-home-city-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" lg="2" xl="2" md="12" sm="12" class="fill-height mb-n10">
          <v-select
              outlined
              v-model="init_group"
              item-text="name"
              item-value="id"
              :items="select_group"
              :menu-props="{ maxHeight: '230' }"
              label="グループ"
              @change="onGroupChange($event)"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" xl="2" md="12" sm="12" class="fill-height mb-n10">
          <v-select
              outlined
              v-model="init_presence"
              item-text="name"
              item-value="id"
              :items="select_presence"
              :menu-props="{ maxHeight: '230' }"
              label="状態"
              @change="onPresenceChange($event)"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <transition mode="out-in">
      <UserWhiteboard v-if="toggle_exclusive === 0" :list="changeData" :group="group" :presence="presence" ></UserWhiteboard>
      <UserCardList v-if="toggle_exclusive === 1" :list="changeData" :group="group" :presence="presence" ></UserCardList>
      <UserCommonList v-if="toggle_exclusive === 2" :list="changeData" :group="group" :presence="presence"></UserCommonList>
      <UserArrivalAllList v-if="toggle_exclusive === 3" :list="changeData" :group="group" :presence="presence"></UserArrivalAllList>
    </transition>
  </v-container>
</template>

<script>

import firebase, {firebase_db} from "../../../plugins/firebase";
import UserWhiteboard from "../../molecules/UserWhiteboard";
import UserCommonList from "../../molecules/UserCommonList";
import UserCardList from "../../molecules/UserCardList";
import UserArrivalAllList from "../../molecules/UserArrivalAllList";

export default {
  name: "AllList",
  components: {
    UserArrivalAllList,
    UserWhiteboard,
    UserCommonList,
    UserCardList,
  },
  data: () => ({
    toggle_exclusive: 0,
    users : [],
    presence : [],
    group: [],
    select_group: [],
    init_group: { name: 'すべて', id: "all" },
    select_presence: [],
    init_presence: { name: 'すべて', id: "all" },
    active_group: 'all',
    active_presence: 'all',
  }),
  created() {
    firebase.onAuth();
    this.getCollectionUsers();
    this.getCollectionPresence();
    this.getCollectionGroups();
  },
  computed: {
    changeData() {
      const presence = this.active_presence
      const group = this.active_group
      let users = this.users.filter(function (item) {
        if (presence === 'all') {
          return true
        } else if (presence.toString() === item.presence_status.toString()) {
          return true
        }
      }, presence);

      //本社縛り( 山口あり )
      users = users.filter(function (item) {
        if (item.office_location === 1 || item.office_location === 2) {
          return true
        }
      });

      return users.filter(function (item) {
        if (group === 'all') {
          return true
        } else if (group.toString() === item.group_id.toString()) {
          return true
        }
      }, group);
    }
  },
  methods: {
    getCollectionUsers() {
      let collection = firebase_db.collection('users').orderBy(`employee_id`)
      collection.onSnapshot(snapshot => {
        this.users = [];
        snapshot.forEach(doc => {
          this.users.push(doc.data())
        })
      });
    },
    getCollectionGroups() {
      firebase_db.collection('group').orderBy('sort').onSnapshot(snapshot => {
        this.group = [];
        this.select_group = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.group.push(tmpVar);
          this.select_group.push(tmpVar);
        })
      });
    },
    getCollectionPresence() {
      firebase_db.collection('presence').orderBy('sort').onSnapshot(snapshot => {
        this.presence = [];
        this.select_presence = [{ name: 'すべて', id: "all" }];
        snapshot.forEach(doc => {
          let tmpVar = doc.data();
          tmpVar["id"] = doc.id;
          this.presence.push(tmpVar);
          this.select_presence.push(tmpVar);
        })
      });
    },
    onGroupChange(event) {
      this.active_group = event;
    },
    onPresenceChange(event) {
      this.active_presence = event;
    }
  }
}
</script>

<style scoped>

.v-enter-active, .v-leave-active {
  transition: opacity .3s ease;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.div-margin {
  margin: 20px;
}

</style>